import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import logoBg from "../assets/images/bg-full.webp";
import Icon from "./elements/Icon";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const R = require('ramda');

export default function PriceBlock() {

    const prices = useStaticQuery(graphql`
    query PricesQuery {
      allWp {
        nodes {
          options {
            acf_prices {
             acfPriceTitle
             acfPriceDesc
             acfPriceBelow
                 prijzen {
                  priceTitle
                  pricePrijs
                  priceFrequency
                  priceUsps {
                     usp
               }
             }
            }
          }
        }
      }
    }
  `);

    const pricesContent = R.path(['allWp', 'nodes', 0, 'options', 'acf_prices'])

    const pricesData = pricesContent(prices);

    const renderPrices = (prices) => {
        return Object.values(prices).map((price, s) => {
            return (
                <div key={`price-${s}`} className="bg-white p-7 shadow-lg price-block flex flex-1 flex-col mx-10 lg:mx-0 lg:mb-0 mb-10">
                    <div className="text-center">
                        <strong className="text-center font-bold uppercase mb-4 text-xl">{price.priceTitle}</strong>
                        <div
                            style={{backgroundImage: `url("${logoBg}")`}}
                            className="my-3 price-bg font-bold text-4xl p-10 bg-contain bg-center bg-no-repeat"
                        >
                            {price.pricePrijs}
                        </div>
                        <div className="font-bold mb-10">
                            {price.priceFrequency}
                        </div>
                        <div className="pb-6">
                            <ul className="text-left">
                                {Object.values(price.priceUsps).map((usp, i) => {
                                    return (
                                        <li className="mb-3" key={`usp-${i}`}>
                                            <div className="flex">
                                                <div className="block mr-3 h-100">
                                                    <Icon icon="CheckCircle" classes="inline-block" size="20"/>
                                                </div>
                                                <div className="block"> {usp.usp} </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="mt-auto">
                        <div className="flex align-middle font-bold">

                            <AnchorLink to="/contact#contactForm"
                                        className={s === 1 ? 'text-center font-bold bg-black hover:bg-primary text-white hover:text-black rounded-md px-10 py-2 transition-colors w-full' :'text-center font-bold bg-secondary-200 hover:bg-primary text-black rounded-md px-10 py-2 transition-colors w-full'}>
                                Kies
                            </AnchorLink>
                        </div>
                    </div>
                </div>
            )
        });
    }

    return (
        <div className="bg-gradient-to-b from-light to-primary py-14">
            <div className="container w-full mx-auto">
                <div className="w-full max-w-none">
                    <div className="text-center mb-10">
                        <div className="font-light text-lg w-2/3 mx-auto" dangerouslySetInnerHTML={{__html: pricesData.acfPriceTitle}}></div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8">
                    {renderPrices(pricesData.prijzen)}
                </div>
            </div>
        </div>
    );
}
